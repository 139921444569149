/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import styles from './OcpLowerRow.scss';
import Button from '../../../../components/Button/Button';

export default class OcpLowerRow extends Component {
  static navigate(url) {
    window.location.href = url;
  }

  constructor() {
    super();
    this.state = {};

    this.setState({ downloadActivities1: false });
    this.setState({ downloadActivities2: false });
  }

  render() {
    console.log(this.state.downloadActivities1);
    console.log(this.state.downloadActivities2);

    return (
      <div className={styles.OcpHomeLowerRow}>
        <div className={styles.OcpHomeLowerRowContainer}>
          <div className={styles.ManualInputContainer}>
            <p>Click on My Downloads page</p>
            <Button onClick={() => OcpLowerRow.navigate('/downloads')} text="My Downloads" />
          </div>
        </div>
      </div>
    );
  }
}
