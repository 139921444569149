import React, { Component } from 'react';
import styles from './OcpTopRow.scss';

export default class OcpTopRow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className={styles.OcpHomeTopRow}>
        <div className={styles.heroContainer}>
          <div className={styles.heroContent}>
            <h1>Offline Content Player Demo Page</h1>
            <h2>Structure content player with offline support</h2>
          </div>
        </div>
      </div>
    );
  }
}
