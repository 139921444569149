import React from 'react';
import PropTypes from 'prop-types';
import styles from './UnitList.scss';
import ProgressiveButton, { STATUS_ENDED, STATUS_NOT_STARTED } from '../ProgressiveButton/ProgressiveButton';

function UnitItem({
  unit = {},
  downloadButton = false,
  downloadCallback,
  userId: loggedUser,
  isPrintViewSelected = false
}) {
  let unitStatus = STATUS_NOT_STARTED;
  if (unit.userId && unit.userId.length > 0) {
    unitStatus = unit.userId.includes(loggedUser) ? STATUS_ENDED : STATUS_NOT_STARTED;
  }
  return (
    <div className={styles.unitItem}>
      <div className={styles.unitNameColumn}>
        <div className={styles.unitName}>{unit.levelName}</div>
      </div>
      {unit.size && (
        <div className={styles.sizeColumn}>
          <div className={styles.size}>{unit.size}</div>
        </div>
      )}
      <div className={styles.actionsColumn}>
        {downloadButton && (
          <ProgressiveButton
            initialStatus={unitStatus}
            startCallback={async () => {
              await downloadCallback(unit.uId, isPrintViewSelected);
            }}
          />
        )}
      </div>
    </div>
  );
}

UnitItem.propTypes = {
  unit: PropTypes.object,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  isPrintViewSelected: PropTypes.bool.isRequired,
  userId: PropTypes.string
};

export default UnitItem;
