/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import styles from './OcpLowerRow.scss';
import Button from '../../../../components/Button/Button';
import MultipleContentFrameContainer from '../../../../components/MultipleContentFrameContainer/MultipleContentFrameContainer';

export default class OcpLowerRow extends Component {
  static navigate(url) {
    window.location.href = url;
  }

  constructor() {
    super();
    this.state = {};

    this.setState({ downloadActivities1: false });
    this.setState({ downloadActivities2: false });
  }

  render() {
    return (
      <div className={styles.OcpHomeLowerRow}>
        <div className={styles.OcpHomeLowerRowContainer}>
          <div className={styles.ManualInputContainer}>
            <p>Launch the product with this button</p>
            <Button onClick={() => OcpLowerRow.navigate('/downloads')} text="My Downloads" />
            <Button
              onClick={() => OcpLowerRow.navigate('/offline-launch/teacher/CPTEBOOKSB-SDF-123')}
              text="Launch Product"
            />
            <Button
              onClick={() => {
                this.setState({ downloadActivities1: true });
              }}
              text="Download lesson 1"
            />
            <Button
              onClick={() => {
                this.setState({ downloadActivities2: true });
              }}
              text="Download lesson 2"
            />
            <Button
              onClick={() => {
                this.setState({ downloadActivities1: false });
                caches.delete('oup-elt-prod_219025');
                caches.delete('oup-elt-prod_222463');
                caches.delete('oup-elt-prod_219055');
                caches.delete('oup-elt-prod_219240');
                caches.delete('oup-elt-prod_219241');
                caches.delete('oup-elt-prod_219246');
                caches.delete('oup-elt-prod_219250');
              }}
              text="Delete lesson 1"
            />
            <Button
              onClick={() => {
                this.setState({ downloadActivities2: false });
                caches.delete('oup-elt-prod_219693');
                caches.delete('oup-elt-prod_219779');
                caches.delete('oup-elt-prod_219782');
                caches.delete('oup-elt-prod_219773');
                caches.delete('oup-elt-prod_219738');
                caches.delete('oup-elt-prod_219736');
                caches.delete('oup-elt-prod_219784');
              }}
              text="Delete lesson 2"
            />
            <Button
              onClick={async () => {
                const cache = await caches.open('cache-without-iframe');
                cache.addAll([
                  'http://localhost:7709/static/content/oup-elt-prod_219025/index.html',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/data.js',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/player.js',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/tincan.js',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/tincan.xml',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/LearningObjectInfo.xml',
                  'http://localhost:7709/static/content/oup-elt-prod_219025/media/S3_35805_BI2e3SB058a.jpg'
                ]);
              }}
              text="Cache activity 219025 without iframe"
            />
            {this.state.downloadActivities1 ? (
              <MultipleContentFrameContainer
                frames={[
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219025/index.html`,
                    title: 'oup-elt-prod_219025'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_222463/index.html`,
                    title: 'oup-elt-prod_222463'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219055/index.html`,
                    title: 'oup-elt-prod_219055'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219240/index.html`,
                    title: 'oup-elt-prod_219240'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219241/index.html`,
                    title: 'oup-elt-prod_219241'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219246/index.html`,
                    title: 'oup-elt-prod_219246'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219250/index.html`,
                    title: 'oup-elt-prod_219250'
                  }
                ]}
                currentFrameIndex="1"
                loadingMode="preload-all"
                persistenceMode="presist-all-frames"
              />
            ) : (
              ''
            )}
            {this.state.downloadActivities2 ? (
              <MultipleContentFrameContainer
                frames={[
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219693/index.html`,
                    title: 'oup-elt-prod_219693'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219779/index.html`,
                    title: 'oup-elt-prod_219779'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219782/index.html`,
                    title: 'oup-elt-prod_219782'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219773/index.html`,
                    title: 'oup-elt-prod_219773'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219738/index.html`,
                    title: 'oup-elt-prod_219738'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219736/index.html`,
                    title: 'oup-elt-prod_219736'
                  },
                  {
                    src: `http://localhost:7709/static/content/oup-elt-prod_219784/index.html`,
                    title: 'oup-elt-prod_219784'
                  }
                ]}
                currentFrameIndex="1"
                loadingMode="preload-all"
                persistenceMode="presist-all-frames"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
