import { OcpLayoutConstants } from '../../../globals/ocpConstants';

const evaluateLayoutProps = ({ pathname, isEmbedded }) => {
  const layoutProps = {
    pageTitle: 'OCP',
    headerProps: {
      isEmbedded: false,
      hideRegisterLink: false,
      hideSignInLink: false,
      hideWidgetDropDown: false,
      hideSubHeader: true,
      disableLogoLink: false,
      hideHelpLink: true,
      hideMyAccountText: true,
      hideBurgerMenu: false,
      darkLayout: true,
      hideLanguageSelector: true
    },
    footerProps: {
      hideUpperFooter: true,
      darkLayout: true
    }
  };

  if (pathname === OcpLayoutConstants.PATH_NAMES.HOME_PAGE) {
    layoutProps.headerProps.darkLayout = true;
    layoutProps.footerProps.hideUpperFooter = false;
    layoutProps.footerProps.darkLayout = true;
  }

  if (isEmbedded) {
    layoutProps.headerProps.isEmbedded = true;
  }

  return layoutProps;
};

export default evaluateLayoutProps;
