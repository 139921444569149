import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener.js';

import OCPLayout from './OCPLayout';
import NotFoundView from '../views/errors/NotFoundView.js';

import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage.js';
import MyDownloadsPage from '../routes/Offline/MyDownloadsPage/MyDownloadsPage.js';
import ProductDownloadPage from '../routes/Offline/ProductDownloadPage/ProductDownloadPage.js';
import OcpDemo from '../routes/OcpDemo/OcpDemo.js';
import OfflineUserProfiles from '../routes/Offline/OfflineUserProfiles/OfflineUserProfiles.js';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          <Route exact path="/">
            <OCPLayout>
              <OfflineUserProfiles />
            </OCPLayout>
          </Route>

          <Route path="/offline-launch/:role/:contentCode/:userId">
            <OCPLayout>
              <StructuredContentPlayerBlankPage />
            </OCPLayout>
          </Route>

          <Route path="/downloads/:userId">
            <OCPLayout>
              <MyDownloadsPage />
            </OCPLayout>
          </Route>

          <Route path="/downloads-embedded">
            <OCPLayout embedded>
              <MyDownloadsPage />
            </OCPLayout>
          </Route>

          <Route path="/product-download">
            <OCPLayout embedded>
              <ProductDownloadPage />
            </OCPLayout>
          </Route>

          <Route path="/offline-demo">
            <OCPLayout>
              <OcpDemo />
            </OCPLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <OCPLayout>
              <NotFoundView />
            </OCPLayout>
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
