import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Home page components
import OcpTopRow from './parts/OcpTopRow/OcpTopRow.js';

import styles from './OcpDemo.scss';

import cmsContent from '../../utils/cmsContent.js';
import OcpLowerRow from './parts/OcpLowerRow/OcpLowerRow.js';

export default class OcpHomepage extends Component {
  constructor() {
    super();
    this.state = { CMS: {} };
  }

  componentWillMount() {
    this.setState({ CMS: cmsContent.ocpHomepage || {} });
  }

  componentWillReceiveProps() {
    this.setState({ CMS: cmsContent.ocpHomepage || {} });
  }

  render() {
    const { CMS } = this.state;

    return (
      <div className={styles.OcpHomePageContainer}>
        <Helmet title={CMS.pageTitle} />
        <OcpTopRow />
        <OcpLowerRow />
      </div>
    );
  }
}
