import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './MyDownloads.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import SearchInput from '../SearchInput/SearchInput';
import HubFilterBar from '../HubFilterBar/HubFilterBar';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

import { deleteOfflineUnits, getOfflineUnits } from '../../redux/actions/offlineContentPlayer.js';
import Cover from '../Cover/Cover';

function MyDownloads({
  localizedContent: { myDownloads: content },
  key,
  storedOfflineUnits,
  getOfflineUnitsAction,
  deleteOfflineUnitsAction
}) {
  const [units, setUnits] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [sortOption, setSortOption] = useState('date');
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const { userId: offlineUserId } = useParams();
  const [filteredUnits, setFilteredUnits] = useState([]);

  const reRender = () => {
    forceUpdate();
  };

  const dynamicSort = property => {
    let sortOrder = 1;
    let sortProperty = property;
    if (sortProperty[0] === '-') {
      sortOrder = -1;
      sortProperty = sortProperty.substr(1);
    }
    return (a, b) => {
      const result2 = a[sortProperty] > b[sortProperty] ? 1 : 0;
      const result = a[sortProperty] < b[sortProperty] ? -1 : result2;
      return result * sortOrder;
    };
  };

  const handleSearch = () => {
    getOfflineUnitsAction({ searchWord: searchWord.toLowerCase() });
    setSearchMode(true);
  };

  const reOrder = sortValue => {
    const newUnits = units.sort(dynamicSort(sortValue));
    setUnits(newUnits);
  };

  useEffect(() => {
    setUnits(storedOfflineUnits);
  }, [storedOfflineUnits]);

  useEffect(() => {
    let filtered = [];
    if (units && units.length > 0) {
      filtered = units.filter(unit => {
        const isExpired = unit.date ? new Date(unit.date) < new Date() : false;
        return unit.userId === offlineUserId && !isExpired;
      });
    }
    setFilteredUnits(filtered);
  }, [units]);

  const renderDownloadedProducts = () => (
    <div className={styles.materials}>
      {filteredUnits && filteredUnits.length > 0 && <h2>{content.title}</h2>}
      {filteredUnits.map((item, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.cover}>
            <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
              <Cover isbn={item.isbn} alt={item.name} />
            </a>
          </div>
          <div className={styles.info}>
            <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
              <div className={styles.title}>{item.name}</div>
              <div className={styles.description}>{item.description}</div>
            </a>
          </div>
          <div className={styles.actions}>
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                deleteOfflineUnitsAction([item.id]);
                reRender();
              }}
            >
              <SVGIcon glyph={GLYPHS.ICON_DELETE} /> {content.delete_item}
            </a>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {((filteredUnits && filteredUnits.length > 0) || searchMode) && (
        <div key={key}>
          <div className={styles.filterBar}>
            <HubFilterBar
              key=""
              idPrefix=""
              filterButtonText=""
              overlayLabelText=""
              hasSortButton={false}
              sortOptions={[
                {
                  text: content.sort_by_date,
                  id: `sort1`,
                  name: 'sortDate',
                  value: `date:asc`,
                  checked: sortOption === 'date',
                  onChange: () => {
                    setSortOption('date');
                    reOrder('date');
                  }
                },
                {
                  text: content.sort_by_name,
                  id: `sort2`,
                  name: 'sortName',
                  value: `name:asc`,
                  checked: sortOption === 'name',
                  onChange: () => {
                    setSortOption('name');
                    reOrder('name');
                  }
                }
              ]}
              sortValue={`${sortOption}:asc`}
              ariaControls="searchResults"
            />
          </div>
          <div className={styles.searchBar}>
            <SearchInput
              placeholder={content.search_placeholder}
              onChange={word => {
                setSearchWord(word);
              }}
              buttonAction={() => {
                handleSearch();
              }}
            />
          </div>
          {renderDownloadedProducts()}
        </div>
      )}
      {(!filteredUnits || (filteredUnits && filteredUnits.length === 0)) && (
        <div className={styles.noDownloads}>{content.no_downloads}</div>
      )}
    </>
  );
}

MyDownloads.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  key: PropTypes.number,
  storedOfflineUnits: PropTypes.array,
  getOfflineUnitsAction: PropTypes.func.isRequired,
  deleteOfflineUnitsAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ offlineContentPlayer }) => ({
      storedOfflineUnits: offlineContentPlayer.units
    }),
    {
      getOfflineUnitsAction: getOfflineUnits,
      deleteOfflineUnitsAction: deleteOfflineUnits
    }
  ),
  withLocalizedContent('myDownloads')
)(MyDownloads);
