import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './MultipleContentFrameContainer.scss';
import { LOADING_MODE, PERSISTENCE_MODE } from './constants.js';

function MultipleFrameContainer({
  frames,
  customClassName = '',
  currentFrameIndex = 0,
  loadingMode = LOADING_MODE.LOAD_ON_NAVIGATION,
  persistenceMode = PERSISTENCE_MODE.PERSIST_NO_FRAMES
}) {
  const ref = useRef([]);

  if (frames.length === 0) {
    return null;
  }

  if (loadingMode === LOADING_MODE.PRELOAD_ALL) {
    return (
      <div className={styles.container}>
        {frames.map(({ title, src, ...props }, index) => (
          <iframe
            key={index}
            className={`${customClassName} ${index === currentFrameIndex ? styles.show : styles.hide}`}
            title={title}
            src={src}
            {...props}
          />
        ))}
      </div>
    );
  }

  if (loadingMode === LOADING_MODE.LOAD_ON_NAVIGATION) {
    if (persistenceMode === PERSISTENCE_MODE.PERSIST_ALL_FRAMES) {
      const activeFrame = frames[currentFrameIndex];
      let activeFrameIndex = ref.current.findIndex(frame => frame.src === activeFrame.src);

      if (ref.current.length === 0 || activeFrameIndex < 0) {
        ref.current.push(activeFrame);
        activeFrameIndex = ref.current.length - 1;
      }

      return (
        <div className={styles.container}>
          {ref.current.map(({ title, src, ...props }, index) => (
            <iframe
              key={index}
              className={`${customClassName} ${index === activeFrameIndex ? styles.show : styles.hide}`}
              title={title}
              src={src}
              {...props}
            />
          ))}
        </div>
      );
    }

    const { title, src, ...props } = frames[currentFrameIndex];
    return src ? (
      <div className={styles.container}>
        <iframe className={customClassName} title={title} src={src} {...props} />
      </div>
    ) : null;
  }
}

MultipleFrameContainer.propTypes = {
  frames: PropTypes.array,
  customClassName: PropTypes.string,
  currentFrameIndex: PropTypes.number,
  loadingMode: PropTypes.string,
  persistenceMode: PropTypes.string
};

export default MultipleFrameContainer;
