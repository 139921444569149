import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import { ICON_DOWNLOAD, ICON_LOADING, ICON_CHECK_CIRCLE } from '@oup/shared-front-end/src/svg/oup/index';
import withLocalizedContent from '../../language/withLocalizedContent';

export const STATUS_NOT_STARTED = 1;
export const STATUS_STARTED = 2;
export const STATUS_ENDED = 3;
function ProgressiveButton({
  startCallback,
  initialStatus,
  labels,
  icons,
  localizedContent: { progressiveButton: content }
}) {
  const [currentStatus, setCurrentStatus] = useState();

  useEffect(() => {
    setCurrentStatus(initialStatus || STATUS_NOT_STARTED);
  }, []);

  const { labelNotStarted = null, labelStarted = null, labelEnded = null } = labels || {};
  const { iconNotStarted = null, iconStarted = null, iconEnded = null } = icons || {};

  let label;
  let icon;
  if (currentStatus === STATUS_STARTED) {
    label = labelStarted || content.default_label_started;
    icon = iconStarted || { component: <ICON_LOADING /> };
  } else if (currentStatus === STATUS_ENDED) {
    label = labelEnded || content.default_label_ended;
    icon = iconEnded || { component: <ICON_CHECK_CIRCLE /> };
  } else {
    label = labelNotStarted || content.default_label_not_started;
    icon = iconNotStarted || { component: <ICON_DOWNLOAD /> };
  }

  return (
    <Button
      variant="transparent"
      size="small"
      text={label}
      icon={icon}
      onClick={async () => {
        if (currentStatus === STATUS_ENDED) {
          return;
        }
        setCurrentStatus(STATUS_STARTED);
        await startCallback();
        setCurrentStatus(STATUS_ENDED);
      }}
    />
  );
}

ProgressiveButton.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  startCallback: PropTypes.func.isRequired,
  initialStatus: PropTypes.oneOf([STATUS_NOT_STARTED, STATUS_ENDED]),
  labels: PropTypes.object,
  icons: PropTypes.object
};

export default withLocalizedContent('progressiveButton')(ProgressiveButton);
