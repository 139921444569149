import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './MyDownloadsPage.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import MyDownloads from '../../../components/MyDownloads/MyDownloads';

function MyDownloadsPage({ localizedContent: { myDownloadsPage: content } }) {
  const [key] = useState('');

  return (
    <div className={styles.container}>
      <h1>{content.title}</h1>
      <MyDownloads key={key} />
    </div>
  );
}

MyDownloadsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('myDownloadsPage'))(MyDownloadsPage);
